import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import flatpickr from "flatpickr";
import breakpoint from 'alpinejs-breakpoints'

// Composer vendor components...
import './vendor/distortedfusion/blade-components/js-detection.js';

window.flatpickr = flatpickr;

Alpine.plugin(breakpoint);

Livewire.start()
